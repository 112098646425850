// mobile search button
"use strict";

class MobileSearchHandler {
    constructor() {
        this.searchFull = document.querySelector(".search-full");
        this.hiddenSearchBar = document.getElementById("hiddenSearchBar");        
        this.mobileSearchButton = document.querySelector("[data-test='search-icon-mobile']");
        this.searchClose = document.querySelector(".search-close");
        this.searchButton = document.querySelector(".search-box button");
    }

    init() {
        if (this.mobileSearchButton) {
            this.mobileSearchButton.addEventListener("click", (e) => {
                this.searchFull.classList.add("active");
                if (this.hiddenSearchBar) {
                    this.hiddenSearchBar.focus();
                }
                e.preventDefault();
            });
        }

        if (this.searchButton) {
            this.searchButton.addEventListener("click", (e) => {
                this.searchFull.classList.add("active");
                if (this.hiddenSearchBar) {
                    this.hiddenSearchBar.focus();
                }
                e.preventDefault();
            });
        }

        if (this.searchClose) {
            this.searchClose.addEventListener("click", (e) => {
                this.searchFull.classList.remove("active");
                e.preventDefault();
            });
        }
    }
}

export default MobileSearchHandler;
