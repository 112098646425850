// image-resizer.js
"use strict";

import $ from 'jquery';  

const resizer = {
    defaults: {
        containerWidth: -1,
        maxHeight: -1,
        grid: true,
        centerCrop: false,
        ratio: -1,
        targetAttribute: "src",
        checkContainerHeight: false
    },
    options: null,
    currentOffset: null,
    rowImgs: null,
    maxHeight: null,
    setImageHeights: function () {
        for (var rowImgIdx in resizer.rowImgs) {
            if (resizer.rowImgs.hasOwnProperty(rowImgIdx)) {
                var rowImg = resizer.rowImgs[rowImgIdx];
                $(rowImg).attr("effective-height", resizer.maxHeight.toString());
                resizer.processImage(rowImg);
            }
        }
    },
    processImage: function (image) {
        if (!resizer.options) {
            resizer.options = resizer.defaults;
        }
        const width = parseInt(image.attr("effective-width"));
        const height = parseInt(image.attr("effective-height"));
        let src = image.attr("data-src");

        if (!src) {
            return;
        }

        const separator = src.indexOf("?") === -1 ? "?" : "&";

        let newSrc = `${src}${separator}bc=ffffff&w=${width}&h=${height}`;
        if (image.attr("centerCrop")) {
            newSrc += "&useCustomFunctions=1&centerCrop=1";
        }

        const { targetAttribute } = resizer.options;    
        if (targetAttribute === "src") {
            image.attr("src", newSrc)
                .attr("width", width)
                .attr("height", height);
        } else {
            image.attr(resizer.options.targetAttribute, newSrc);
        }
    },
    process: function (selector, options) {
        options = $.extend({}, resizer.defaults, options);
        resizer.options = options;

        resizer.currentOffset = -1;
        resizer.rowImgs = [];
        resizer.maxHeight = 0;

        $(selector).each(function () {
            var originalImgWidth = parseInt($(this).attr("data-width"));
            var originalImgHeight = parseInt($(this).attr("data-height"));
            var ratio;
            if (options.ratio !== -1) {
                ratio = options.ratio;
            } else {
                ratio = originalImgWidth / originalImgHeight;
            }

            if (options.grid) {
                var thisOffset = $(this).offset().top;
                if (thisOffset !== resizer.currentOffset) {
                    if (resizer.currentOffset !== -1) {
                        // Set previous heights now
                        resizer.setImageHeights();

                        resizer.rowImgs = [];
                        resizer.maxHeight = 0;
                    }

                    resizer.currentOffset = thisOffset;
                }
            }

            if (options.centerCrop) {
                $(this).attr("centerCrop", "1");
            }

            var effectiveWidth = options.containerWidth;

            var imageContainer = $(this).closest(".image-container");
            if (imageContainer.length === 0) {
                imageContainer = $(this).parent();
            }

            if (effectiveWidth === -1) {
                effectiveWidth = imageContainer.width();
            }

            if (effectiveWidth > originalImgWidth) {
                effectiveWidth = originalImgWidth;
            }

            var maxHeight = originalImgHeight;
            if (options.maxHeight !== -1) {
                maxHeight = Math.min(maxHeight, options.maxHeight);
            }

            if ($(this).is("[data-max-height]")) {
                maxHeight = Math.min(maxHeight, parseInt($(this).attr('data-max-height')));
            }

            if (options.checkContainerHeight) {
                maxHeight = Math.min(maxHeight, imageContainer.height());
            }

            var effectiveHeight = parseInt(effectiveWidth / ratio);

            if (effectiveHeight > maxHeight) {
                effectiveHeight = maxHeight;
                effectiveWidth = parseInt(effectiveHeight * ratio);
            }

            $(this).attr("effective-width", effectiveWidth.toString())
                .attr("effective-height", effectiveHeight.toString());

            if (options.grid) {
                resizer.maxHeight = Math.max(resizer.maxHeight, effectiveHeight);
                resizer.rowImgs.push($(this));
            } else {
                resizer.processImage($(this));
            }
        });

        if (options.grid) {
            // Process any remaining images
            resizer.setImageHeights();
        }
    }
};

export default resizer;

 