//sidebar.js
"use strict";

import $ from 'jquery'; 

export default class Sidebar {
    constructor() {
        this.menuSidebarToggle = document.querySelector(".menu-sidebar-toggle");
        this.cartSidebarToggles = document.querySelectorAll(".cart-sidebar-toggle");
        this.handleScroll();
    
        if (this.cartSidebarToggles.length > 0) {
            this.handleSidebarToggle();
        }

        if (this.menuSidebarToggle) {
            this.handleMenuSidebar();
        }
        
        $(".menu-close-trigger, .menu-overly-mask").on('click', function (event) {
            event.preventDefault();
            $('.menu-overly-mask').toggleClass('is-visible');
            $('.menu-sidebar-toggle').toggleClass('active');
            $('.menu-sidebar').toggleClass('is-visible');
        });

        $(".overly-mask, .cart-close-trigger").on('click', function (event) {
            event.preventDefault();
            $('.overly-mask').toggleClass('is-visible');
            $('.cart-sidebar').toggleClass('is-visible')
            $('.cart-sidebar-toggle').toggleClass('active');
            $('.cartMenu ').toggleClass('open');
        });

        $(".search-trigger").on('click', function (e) {
            $('.search-overly-mask').toggleClass("open"); //you can list several class names
            e.preventDefault();
        });

        $(".search-overly-close-trigger").on('click', function (e) {
            $('.search-overly-mask').removeClass("open"); //you can list several class names
            e.preventDefault();
        });
    }

    handleScroll() {
        window.addEventListener('scroll', function () {
            const navbarHero = document.querySelector('.navbar-hero');
            if (navbarHero) {
                if (window.scrollY > 1) {
                    navbarHero.classList.add("sticky");
                } else {
                    navbarHero.classList.remove("sticky");
                }
            }            
        });
    }

    handleMenuSidebar() {
        this.menuSidebarToggle.addEventListener("click", (e) => {
            e.preventDefault();
            this.menuSidebarToggle.classList.toggle("active");
            document.querySelector(".menu-overly-mask").classList.toggle("is-visible");
            document.querySelector(".menu-sidebar").classList.toggle("is-visible");
        });
    }    

    handleSidebarToggle() {
        this.cartSidebarToggles.forEach(cartSidebarToggle => {
            cartSidebarToggle.addEventListener("click", (e) => {
                e.preventDefault();
                cartSidebarToggle.classList.toggle("active");
                document.querySelector(".cart-sidebar").classList.toggle("is-visible");
                document.querySelector(".cartMenu").classList.toggle("open");
                if (document.querySelector(".megamenu").classList.contains("stuck")) {
                    document.querySelector(".cart-sidebar").classList.add("hasTopBar");
                } else {
                    document.querySelector(".cart-sidebar").classList.remove("hasTopBar");
                }
            });
        });

        document.body.addEventListener("click", (e) => {
            this.cartSidebarToggles.forEach(cartSidebarToggle => {
                if (cartSidebarToggle.classList.contains("active") && !e.target.closest(".cart-sidebar") && !e.target.closest(".cart-sidebar-toggle")) {
                    cartSidebarToggle.classList.remove("active");
                    document.querySelector(".cart-sidebar").classList.remove("is-visible");
                    document.querySelector(".cartMenu").classList.remove("open");
                }
            });
        });
    }
}