// index.js
"use strict";

import $ from "jquery";

// global
window.jQuery = $;
window.$ = $;

import main from './main.js';
import Sidebar from "./modules/sidebar.js";

main();

document.addEventListener('DOMContentLoaded', () => {
    new Sidebar();
});

if (document.querySelector('[js-hook-latestnews]') !== null) {
    document.addEventListener('DOMContentLoaded', function () {
        import('./modules/news.js').then(module => {
            new module.default();
        });
    });
}

if (document.querySelector('[js-hook-home]') !== null) {
    document.addEventListener('DOMContentLoaded', function () {
        import('./modules/carousel.js').then(module => {
            new module.default();
        });
    });

    import('./modules/home.js').then(module => {
        new module.default();
    });
}

if (document.querySelector('[js-hook-minicart]') !== null) {
    import('./modules/minicart.js').then(module => {
        new module.default();
    });
}

if (document.querySelector('[js-hook-productdetail]') !== null) {    
    import('./modules/sticky-basket.js').then(module => {
        new module.default();
    });

    import('./modules/products/product-detail.js').then(module => {
        let config = window.productDetailsBasketConfig;
        const thumbs = document.querySelector('#product-images-thumbnails');
        const images = thumbs ? thumbs.querySelectorAll("li") : [];
        const details = new module.default(config, images);
        details.init();    
    });
}

if (document.querySelector('[js-hook-contact]') !== null) {
    import('./modules/contact.js').then(module => {
        const contact = new module.default();
        contact.init();
    });
}

if (document.querySelector('[js-hook-products-view-filter]') !== null) {
    document.addEventListener('DOMContentLoaded', () => {
        import('./modules/products/product-viewfilter.js').then(module => {
            const productViewFilter = new module.default();
            productViewFilter.init();
        });
    });
}

if (document.querySelector('[js-hook-data-feed]') !== null) {
    document.addEventListener('DOMContentLoaded', function () {
        import('./modules/feeds.js').then(module => {
            const feeds = new module.default();
            feeds.init();
        });
    });
}

if (document.querySelector('[js-hook-client-registration]')) {
    import('./modules/account/registration.js').then(module => {
        new module.default();
    });
}

if (document.querySelector('[js-hook-becomeclient]')) {
    import('./modules/account/become-client.js').then(module => {
        const translations = window.translations;
        const client = new module.default(translations);
        client.init();
    });
}

if (document.querySelector('[js-hook-account-takeover]')) {
    document.addEventListener('DOMContentLoaded', function () {
        import('./modules/account/takeover.js').then(module => {
            const takeover = new module.default();
            takeover.init();
        });
    });
}

if (document.querySelector('[js-hook-account-newsletter]')) {
    document.addEventListener('DOMContentLoaded', function () {
        import('./modules/account/newsletters.js').then(module => {
            const newsletter = new module.default();
            newsletter.init();
        });
    });
}

if (document.querySelector('[js-hook-account-apps]')) {
    import('./modules/account/api-apps.js').then(module => {
        const config = window.apiAppsConfig;
        const apps = new module.default(config);
        apps.init();
    });
}

if (document.querySelector('[js-hook-account-details]')) {
    import('./modules/account/details.js').then(module => {
        const config = window.accountMyDetailsConfig;
        const details = new module.default(config);
        details.init();
    });
}

if (document.querySelector('[js-hook-basket]')) {
    document.addEventListener('DOMContentLoaded', function () {
        import('./modules/checkout/basket.js').then(module => {
            module.default();
        });
    });
}

if (document.querySelector('[js-hook-catalogue]')) {
    document.addEventListener('DOMContentLoaded', function () {
        import('./modules/catalogues.js').then(module => {
            new module.default();
        });      
    });
}

if (document.querySelector('[js-hook-checkout-step1]')) {
    import('./modules/checkout/step1.js').then(module => {
        const config = window.checkout1config
        module.default(config);
    });
}

if (document.querySelector('[js-hook-checkout-step2]')) {
    document.addEventListener('DOMContentLoaded', function () {
        import('./modules/checkout/step2.js').then(module => {
            const config = window.checkout2config
            const checkout = new module.default(config);
        });
    });
}

if (document.querySelector('[js-hook-changepassword-form]')) {
    import('./modules/account/change-password.js').then(module => {
        new module.default();
    });
}

if (document.querySelector('[js-hook-resetpassword-form]')) {
    import('./modules/account/reset-password.js').then(module => {
        new module.default();
    });
}

if (document.querySelector('[js-hook-firstpassw-form]')) {
    import('./modules/account/first-password.js').then(module => {
        new module.default();
    });
}

if (document.querySelector('[js-hook-ideal-form]')) {
    import('./modules/ideal/ideal.js').then(module => {
        new module.default();
    });
}
