// main.js
"use strict";

import $ from 'jquery';
import resizer from './modules/image-resize';
import { Select2 } from 'select2';
import Bloodhound from 'vendor/typeahead/typeahead.bundle';
import { Collapse } from "bootstrap";
import MobileSearchHandler from './utils/mobile-search-button';

export default function () {

    // Mobile device detection and apply class to body
    document.addEventListener('DOMContentLoaded', function() {
        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
    
        if (isMobileDevice()) {
            document.body.classList.add('mobile-device');
        }
    });
    
    $(function () {
        if ($("body").hasClass("loggedIn")) {
            if (accountVariables.HasIdealInvoice) {
                $("#liIdealAccount").show();
            }
        }

        let images = $("img.img-responsive-content");
        resizer.process(images);

        var searchResults = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: '/api/ssi/search/%QUERY',
                wildcard: '%QUERY'
            }
        });

        $('.search-input').typeahead({
            autoselect: false,
            minLength: 2,
            hint: false
        }, {
            name: 'search-results',
            display: 'Title',
            source: searchResults,
            limit: 10
        });

        $('.search-input').on('typeahead:select', function (_ev, suggestion) {
            window.location.href = suggestion.Url;
        });

        $("#accordionNo .panel-collapse").each(function () {
            $(this).on("hidden.bs.collapse", function () {
                $(this).parent().find(".collapseWill").removeClass("active").addClass("hasPlus");
            });

            $(this).on("show.bs.collapse", function () {
                $(this).parent().find(".collapseWill").removeClass("hasPlus").addClass("active");
            });
        });

        if ($("body").width() < 992) {
            $("#accordionNo .panel-collapse").collapse("hide");
        }

        // Customs tree menu script
        $(".dropdown-tree-a").on('click', function () {
            $(this).parent(".dropdown-tree").toggleClass("open-tree active");
        });

        $(function () {
            var navTree = $(".nav-tree li:has(ul)");
            var navTreeA = navTree.addClass("parent_li").find(" > a");

            navTreeA.each(function () {
                if ($(this).hasClass("child-has-open")) {

                } else {
                    $(this).addClass("child-has-close");
                    var navTreeAchildren = $(this).parent("li.parent_li").find(" > ul > li");
                    navTreeAchildren.hide();
                }
            });

            $(".nav-tree li.parent_li > a").on("click", function (e) {
                var children = $(this).parent("li.parent_li").find(" > ul > li");
                if (children.is(":visible")) {
                    children.hide("fast");
                    $(this).addClass("child-has-close").removeClass("child-has-open");

                } else {
                    children.show("fast");
                    $(this).addClass("child-has-open").removeClass("child-has-close");
                }
                e.stopPropagation();
            });
        });

        if (/IEMobile/i.test(navigator.userAgent)) {
            // Detect windows phone//..
            $(".navbar-brand").addClass("windowsphone");
        }

        // top navbar IE & Mobile Device fix
        var isMobile = function () {
            return /(iPad)|(iPhone)|(iPod)|(Android)|(PlayBook)|(BB10)|(BlackBerry)|(Opera Mini)|(IEMobile)|(webOS)|(MeeGo)/i.test(navigator.userAgent);
        };

        // Enable or disable click event on mega menu main menu item on mobile
        if (isMobile()) {
            $("a.dropdown-toggle").removeClass("disabled");
        }

        $(function () {
            var updateStickyNav = function () {
                //Sets the current scroll position
                var scrollTop = $(this).scrollTop() || 0;

                if (scrollTop < threshold) {
                    $body.removeClass("stuckNav").addClass("unstuckNav");
                } else {
                    $body.addClass("stuckNav").removeClass("unstuckNav");
                }

                tshopScroll = scrollTop; // Updates scroll position
            };

            // Keep track of last scroll
            var tshopScroll = 0;
            var threshold = $(".navbar").height() - $("#nav").height();
            var $body = $("body");
            $(window).on('scroll', updateStickyNav);
            updateStickyNav();
        });

        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            // Detect ios User // 
            const parallaxSection = document.querySelector(".parallax-section");
            if (parallaxSection) {
                parallaxSection.classList.add("isios");
            }

            const navbarHeader = document.querySelector(".navbar-header")
            if (navbarHeader) {
                navbarHeader.classList.add("isios");
            }

            const blogIntro = document.querySelector(".blog-intro");
            if (blogIntro) {
                blogIntro.classList.add("isios");
            }

            const productStoryHasbg = document.querySelector(".product-story-hasbg");
            if (productStoryHasbg) {
                productStoryHasbg.classList.add("isios");
            }
        }

        if (/Android|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // Detect Android User //            
            const parallaxSection = document.querySelector(".parallax-section");
            if (parallaxSection) {
                parallaxSection.classList.add("isandroid");
            }
        }

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // Detect Mobile User // No parallax           
            const parallaxSection = document.querySelector(".parallax-section");
            if (parallaxSection) {
                parallaxSection.classList.add("ismobile");
            }

            const parallaximg = document.querySelector(".parallaximg");
            if (parallaximg) {
                parallaximg.classList.add("ismobile");
            }
        } else {
            // All Desktop 
            $(window).on("scroll", function () {
                parallaxScroll();
            });

            function parallaxScroll() {
                var scrolledY = $(window).scrollTop();
                $(".parallaximg").css("marginTop", "" + ((scrolledY * 0.3)) + "px");
                $(".parallax-windowz").css("background-position", "center -" + ((scrolledY * 0.1)) + "px");
            }
        }

        $(function () {
            $("select.select2").select2();
        });

        $(".scrollto").on('click', function (event) {
            event.preventDefault();
            //calculate destination place
            var dest;
            if ($(this.hash).offset().top > $(document).height() - $(window).height()) {
                dest = $(document).height() - $(window).height();
            } else {
                dest = $(this.hash).offset().top;
            }

            // go to destination
            $("html,body").animate({
                scrollTop: dest - 51
            }, 1000, "swing");
        });

        // Mobile search button
        const mobileSearchHandler = new MobileSearchHandler();
        mobileSearchHandler.init();
    });
}